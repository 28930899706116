import styles from "../styles/locations";
import React, {useState} from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery, useQueryClient} from "react-query";
import {getReservations} from "../api/reservations";
import {dateEnToFr} from "../utils/utils";
import ReactPaginate from 'react-paginate';
import {getMe} from "../api/users";
import {useNavigate, useParams} from "react-router-dom";
import moment from 'moment';
import {getProperty} from "../api/properties";
import Header from "../components/header";
moment.locale('fr', {
    months : 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
    monthsShort : 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

function Reservations() {
    const navigate = useNavigate();
    const { logement_id } = useParams();
    const queryClient = useQueryClient();
    const years = [2020, 2021, 2022, 2023, 2024, 2025];
    const [year, setYear] = useState(new Date().getFullYear());

    const {data: reservations} = useQuery(['reservations', year], () => getReservations({year, logement_id}));
    const {data: property} = useQuery('property', () => getProperty(logement_id), {enabled: !!logement_id});
    console.log(JSON.stringify(logement_id));
    console.log(JSON.stringify(property));

    const {data: me} = useQuery('me', () => getMe(navigate));
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    //const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    //const itemsPerPage = 15;
    //const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //const currentReservations = reservations?.reverse().slice(itemOffset, endOffset);
    const currentReservations = reservations?.reverse();
    //const pageCount = Math.ceil(reservations?.length / itemsPerPage);

    const changeYear = async (y) => {
        //await setItemOffset(0);
        await setYear(y);
        await queryClient.invalidateQueries(['reservations', y])
    }

    // Invoke when user click to request another page.
    /*const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % reservations?.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };*/

    const isYear = (y) => {
        return y === year ? 'true' : 'false';
    }

    return (
        <div style={styles.page}>
            <Header />
            <Navbar page={'reservations'} me={me} />
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Liste des <span style={styles.h1W}>réservations</span> {!!logement_id && 'du logement'} {property?.name}
                </div>
            </div>

            <div style={styles.containerFluid}>
                <div style={styles.contentCard}>
                    {years?.map(y => (
                        <div style={styles.years}>
                            <a className="changeYearLink" onClick={() => changeYear(y)}>{y}</a>
                        </div>
                    ))}
                </div>
                <div style={styles.contentCard}>
                    {!reservations && <div style={styles.shadow}>
                        <div style={styles.Card}>
                            <div style={styles.Card__title}>
                                <div style={styles.Card__title__name}>
                                    Chargement en cours
                                </div>
                            </div>
                        </div>
                    </div>}
                    {reservations?.length === 0 && 'Aucune réservation'}

                    {currentReservations?.map((reservation, i, array) => {
                        let date ;
                        if (i === 0 || moment(array[i-1].start).format("MMMM") !== moment(reservation.start).format("MMMM")) {
                            date = (<div style={styles.date}>
                                <div style={styles.shadow100}>
                                    <div style={styles.Card100}>
                                        <div style={{...styles.Card__title, ...styles.backgroundColorGray}}>
                                            <div style={styles.Card__title__name}>
                                                <p style={{fontSize: 30}}>{moment(reservation.start).format("MMMM")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        } else {
                            date = (<div></div>);
                        }
                        return (
                            <>
                                {date}
                                <a href={`/reservations/${reservation.id}`} style={styles.shadow}>
                                    <div style={styles.Card}>
                                        <div style={styles.Card__title}>
                                            <div style={styles.Card__title__name}>
                                                {!logement_id && (<div>{reservation?.property_object?.name}</div>)}
                                                <div>{reservation?.client_name}</div>
                                            </div>
                                            <div style={styles.Card__subtitle__name}>
                                                du {dateEnToFr(reservation.arrival_date)} au {dateEnToFr(reservation.departure_date)}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </>
                        )
                    })}
                </div>
                {/*<div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 20,
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                  }}
                >
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Suivant >"
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        previousLabel="< Précédent"
                        activeClassName={'item active_pagination '}
                        breakClassName={'item break-me '}
                        containerClassName={'pagination'}
                        disabledClassName={'disabled-page'}
                        marginPagesDisplayed={2}
                        nextClassName={"item next "}
                        pageClassName={'item pagination-page '}
                        previousClassName={"item previous"}
                      />
                </div>*/}
                {property && (<div className={'row mb-4'}>
                    <div className={'col-12'}>
                        {years?.map(y => (
                            <div style={isYear(y) === 'true' ? styles.calendarShow : styles.calendarHide}>
                                <section className="card card-table" style={styles.calendar}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {property && (<div id="calendar" className="calendars" data-logement-id={property?.id} data-year={y}>
                                                    <p>Chargement en cours...</p>
                                                </div>)}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        ))}
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default Radium(Reservations);
